import { useSelector } from 'react-redux';

const usePermission = () => {
  const user = useSelector((state) => state.user.data);

  const replace = (name) => {
    return name.replaceAll(' ', '_').toLowerCase();
  };

  const hasPermission = (permissions) => {
    if (typeof permissions === 'string') {
      const perm = replace(permissions);

      return (
        user.is_admin ||
        user.role_permissions.names.map((role) => replace(role)).includes(perm) ||
        user.direct_permissions.map((role) => replace(role)).includes(perm) ||
        user.role_permissions.url.includes(permissions)
      );
    }

    return false;
  };

  return { hasPermission };
};

export default usePermission;
